<template>
    <div style="height: 66px">
        <b-navbar toggleable="sm" type="light" variant="light">
            <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
            <b-navbar-brand href="/">
                <img src="@common/assets/sentera-logo.png" style="width:50px;height:50px">
                MF Stand QA Tool
                </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav id="navbar">
                    <router-link to="/">Home</router-link>
                    <router-link to="/plant_count_qa">Plant Count QA</router-link>
                    <router-link to="/logout">Logout</router-link>
                    
                    <!--This is really poor practice-->
                    <div id="navbar-image-name-div" style="padding-left:15px;padding-right:15px" v-if="is_ppa_qa">
                        <p id="navbar-image-name">
                            &nbsp;&nbsp;Image: {{this.image_name}}
                        </p>
                    </div>
                    <div id="navbar-button-div" v-show="is_ppa_qa">
                        </div>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <router-view></router-view>
    </div>
</template>

<style scoped>
    p { margin:0 }
</style>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    data() {
        return {
            subimage_idx: -1,
            image_name: "",
            n_h_subimages: -1,
            n_w_subimages: -1,
            gender_qa: false,
        };
    },
    computed: {
        is_ppa_qa() {
            // If no image_name passed in route params, use store value
            return this.$route.name === "PPA QA";
        }
    },
    methods: {
        goToReject() {
        },
    },
    components: { }
})
</script>

<style scoped>
    p { 
        margin:0;
        font-size: 13px; 
    }
    a { 
        text-decoration: none;
        color: #000;
        padding: 0 10px 0 10px;
    }
</style>
