<template>
  <div id="app">
    <Navbar></Navbar>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
    name: "app",
    components: {
        Navbar,
    },
    data: () => {
        return {
            user: undefined,
            authState: undefined,
            unsubscribeAuth: undefined,
            formFields: [
              { type: 'password' },
              { type: 'email' }
            ],
        };
    },

    created() {
    },
    
    beforeDestroy() {
    },

    computed: {
      show_logged_in_as() {
        return (this.$route.name == "Home");
      },

      show_signout() {
        return (this.$route.name == "Home");
      }
    },
};
</script>

<style>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

</style>
