//API endpoints, consolidated

import { field_json_args, field_json_image_name_args, api_consolidator_error_handler } from "./api_utils"


export async function get_all_rgb_filenames(...args) {
    return api_consolidator_error_handler("get_all_rgb_filenames", "filenames", field_json_args(...args))
}

export async function get_progress_json(...args) {
    return api_consolidator_error_handler("get_progress_json", "progress_json", field_json_args(...args))
}

export async function get_next_image_to_qa(field_json, image_name, qa_type, user) {
    return api_consolidator_error_handler("get_next_image_to_qa", "next_image", {field_json: field_json, image_name: image_name, qa_type: qa_type, user: user})
}

export async function get_next_outsourced_image_to_qa(user) {
    return api_consolidator_error_handler("get_next_outsourced_image_to_qa", "next_image", {user: user})
}

export async function get_confidence_stats(field_json) {
    return api_consolidator_error_handler("get_confidence_stats", "stats", field_json_args(field_json))
}

////////////////
// THUMBNAILS //
////////////////
export async function ecs_create_rgb_thumbnails(field_json) {
    return api_consolidator_error_handler("ecs_create_thumbnails", null, {field_json: field_json, img_folder_name: "RGB", size: "(1200, 900)"})
}

export async function ecs_create_bin_thumbnails(field_json, bin_folder) {
    return api_consolidator_error_handler("ecs_create_thumbnails", null, {field_json: field_json, img_folder_name: bin_folder, size: "(1200, 900)"})
}

export async function ecs_create_row_thumbnails(field_json) {
    return api_consolidator_error_handler("ecs_create_thumbnails", null, {field_json: field_json, img_folder_name: "RGB", size: "(1200, 900)", anno: "True"})
}

export async function ecs_create_single_image_thumbnails(field_json, image_name) {
    return api_consolidator_error_handler("ecs_create_thumbnails", null, {field_json: field_json, img_folder_name: "RGB", size: "(1200, 900)", anno: "True", single_image: image_name})
}

export async function get_image_and_annotations_api(field_json, annotation_dir, image_name) {
    return api_consolidator_error_handler("get_image_and_annotations", null, {field_json: field_json, annotation_dir: annotation_dir, image_name: image_name})
}

//////////
// TAGS //
//////////
export async function get_single_image_tags(field_json, image_name) {
    return api_consolidator_error_handler("get_image_tags", "tags", {field_json: field_json, image_name: image_name})
}

export async function get_all_image_tags(...args) {
    return api_consolidator_error_handler("get_all_image_tags", "tags", field_json_args(...args))
}

export async function get_image_tags_callback(field_json, image_name, callback) {
    return api_consolidator_error_handler("get_image_tags", "tags", field_json_image_name_args(field_json, image_name), callback)
}

export async function get_all_field_tags(field_json) { 
    return api_consolidator_error_handler("get_all_field_tags", null, field_json_args(field_json))
}

export async function set_field_tag(field_json, tag_name, tag_dict) { 
    return api_consolidator_error_handler("set_field_tag", null, {field_json: field_json, tag_name: tag_name, tag_dict: tag_dict})
}

////////////////
// IMAGE URLS //
////////////////
export async function get_rgb_url(...args) {
    return api_consolidator_error_handler("get_rgb_url", "url", field_json_image_name_args(...args))
}

export async function get_binary_url(field_json, image_name, binary_set) {
    let ext = image_name.split(".").slice(-1)[0];
    if (ext != "png" && ext != "PNG") {
        image_name = image_name.replace(ext, "png");
    }
    return api_consolidator_error_handler("get_binary_url", "url", {field_json: field_json, image_name: image_name, binary_set: binary_set})
}

export async function get_all_rgb_urls(...args) {
    return api_consolidator_error_handler("get_all_rgb_urls", "urls", field_json_args(...args))
}

export async function get_all_bin_urls(field_json, binary_set) {
    return api_consolidator_error_handler("get_all_binary_urls", "urls", {field_json: field_json, binary_set: binary_set})
}

////////////////////
// THUMBNAIL URLS //
////////////////////
export async function get_all_rgb_thumbnail_urls(field_json) {
    return api_consolidator_error_handler("get_all_thumbnail_urls", "urls", {field_json: field_json, img_folder_name: "RGB"})
}

export async function get_all_bin_thumbnail_urls(field_json, bin_folder) {
    return api_consolidator_error_handler("get_all_thumbnail_urls", "urls", {field_json: field_json, img_folder_name: bin_folder})
}

export async function get_all_row_thumbnail_urls(field_json) {
    return api_consolidator_error_handler("get_all_thumbnail_urls", "urls", {field_json: field_json, img_folder_name: "row"})
}

export async function get_all_yellow_keypoint_thumbnail_urls(field_json) {
    return api_consolidator_error_handler("get_all_thumbnail_urls", "urls", {field_json: field_json, img_folder_name: "keypoint_thumbnails/YELLOW"})
}

export async function get_all_red_keypoint_thumbnail_urls(field_json) {
    return api_consolidator_error_handler("get_all_thumbnail_urls", "urls", {field_json: field_json, img_folder_name: "keypoint_thumbnails/RED"})
}

export async function get_all_pink_keypoint_thumbnail_urls(field_json) {
    return api_consolidator_error_handler("get_all_thumbnail_urls", "urls", {field_json: field_json, img_folder_name: "keypoint_thumbnails/PINK"})
}