/* eslint-disable */
import {CognitoAuth, StorageHelper} from 'amazon-cognito-auth-js';
import IndexRouter from '../router/index';
import UserInfoStore from './user-info-store';
import UserInfoApi from './user-info-api';


const COGNITO_CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const COGNITO_APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const COGNTIO_REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const COGNITO_REDIRECT_LOGOUT_URI = process.env.VUE_APP_COGNITO_REDIRECT_LOGOUT_URI;
const COGNITO_USER_POOL_ID = process.env.VUE_APP_COGNITO_USER_POOL_ID;


var authData = {
    ClientId : COGNITO_CLIENT_ID, // Your client id here
    AppWebDomain : COGNITO_APP_DOMAIN,
    TokenScopesArray : ['openid', 'email', 'profile'],
    RedirectUriSignIn : COGNTIO_REDIRECT_URI,
    RedirectUriSignOut : COGNITO_REDIRECT_LOGOUT_URI,
    UserPoolId : COGNITO_USER_POOL_ID,
}
console.log(authData);

var auth = new CognitoAuth(authData);
console.log(auth);
auth.userhandler = {
    onSuccess: function(result) {
        console.log("On Success result", result);
        UserInfoStore.setLoggedIn(true);
        UserInfoApi.getUserInfo().then(response => {
            IndexRouter.push('/');
        });

        
        
    },
    onFailure: function(err) {
        UserInfoStore.setLoggedOut();
        IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
    }
};

function getUserInfoStorageKey(){
    var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
    var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
    var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
    return userInfoKey;
}

var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();
export default{
    auth: auth,
    login(){
        auth.getSession();
    },
    logout(){
        if (auth.isUserSignedIn()) {
            var userInfoKey = this.getUserInfoStorageKey();
            auth.signOut();

            storage.removeItem(userInfoKey);
        }
    },
    getUserInfoStorageKey,

}
