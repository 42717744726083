<template>
    <div>
        <h2>Home</h2>
        <p>ULabel Keybinds</p>
        <table>
            <tr>
                <td>Keybind</td>
                <td>Action</td>
            </tr>
            <tr>
                <td>1</td>
                <td>Decrease confidence slider by 1%</td>
            </tr>
            <tr>
                <td>2</td>
                <td>Increase confidence slider by 1%</td>
            </tr>
            <tr>
                <td>d</td>
                <td>Delete annotation while hovering</td>
            </tr>
            <tr>
                <td>c</td>
                <td>Create annotation at hover location (keypoints only)</td>
            </tr>
            <tr>
                <td>r</td>
                <td>Resize, zoom to (subimage) extent</td>
            </tr>
            <tr>
                <td>shift+r</td>
                <td>Resize, zoom to (full image) extent</td>
            </tr>
            <tr>
                <td>v</td>
                <td>Vanish toggle, active task only</td>
            </tr>
            <tr>
                <td>shift+v</td>
                <td>Vanish ALL annotations</td>
            </tr>
            <tr>
                <td>b</td>
                <td>Binary toggle, visualization of the binary</td>
            </tr>
            <tr>
                <td>s</td>
                <td>Change keypoint size to small preset</td>
            </tr>
            <tr>
                <td>l</td>
                <td>Change keypoint size to large preset</td>
            </tr>
            <tr>
                <td>=</td>
                <td>Change keypoint size +1</td>
            </tr>
            <tr>
                <td>-</td>
                <td>Change keypoint size -1</td>
            </tr>
            <tr>
                <td>Enter</td>
                <td>Submit image</td>
            </tr>
        </table>
        <p>{{ username }}</p>
    </div>
</template>
<script>
import UserInfoStore from '@/app/user-info-store';
export default {
    name: 'Home',
    data: function() {
        return {
            userInfo: UserInfoStore.state.cognitoInfo,
            username: UserInfoStore.state.cognitoInfo.email
        }
    },
    mounted: function() {
        this.$store.commit("update$user", this.username);
    }
    
}
</script>
<style>
    table {
        border-collapse: collapse;
        margin-left: auto;
        margin-right: auto;

    }
    td {
        border: 1px solid black;
        padding: 5px;
    }
</style>
