<template>
    <div>
        <div id="navbar-back-next">
            <input type="button" id="navbar-break-button" value="Submit and Exit" v-on:click="exit_qa(true)" />
            <input type="button" id="navbar-unlock-button" value="Unlock and Exit" v-on:click="exit_qa(false)" />
        </div>
        <div id="container"></div>
    </div>
</template>

<script>
import $ from "jquery";
// import { ULabel } from "@common/app/ulabel_variants/ulabel_submit_first.js";
import { ULabel } from "@common/app/ulabel_variants/ulabel-keypoint-filter-only-submit-first.js";
import { extend_line, extend_all } from "@common/app/ulabel_extend_line";
import { get_subimage_qa_status } from "@common/app/ddb_utils.js";
import { get_image_and_annotations, getMeta } from "@common/app/annotation_loaders.js";
import { ecs_create_single_image_thumbnails, get_binary_url, get_all_field_tags } from "@common/app/api_endpoints.js";
import { hit_terraform_api } from '@common/app/api_utils.js';
import { delete_annos_in_bbox, delete_annos_in_polygon, change_class } from "@common/app/ulabel_utils.js";
import { lock_image, unlock_image, mark_is_modified_by, get_username } from "@common/app/qa_utils.js";
import { get_next_outsourced_image_to_qa } from "@common/app/api_endpoints.js";
import { make_all_subimages_bboxes_with_large_rejection_x } from "@common/app/ulabel_anno_utils.js";
import UserInfoStore from '@/app/user-info-store';

export default {
    data() {
        return {
            message: "",
            field_name: "",
            field_json: null,
            image_name: "",
            subimage_idx: -2,
            ulabel: null,
            rgb_image_url: "",
            binary_image_url: "",
            rgb_displayed: true,
            qa_status: [], // list of all subimage qa statuses
            subimage_qa_status: "",
            image_tags: "",
            stats: {},
            user: "",
            row_annotations: [],
            n_subimages: 9,
            leave_qa_cycle: false,
            submit_all_subimages: false,
            field_tags: {},
            buffer_pct: 0,
            confidence_threshold: null
        };
    },
    created() {
        this.user = get_username(this);
        if (this.user == "" || this.user == null) {
            this.user = UserInfoStore.state.cognitoInfo.email;
        }

        window.addEventListener("keydown", (e) => {
            extend_line(e, this.ulabel, "row_classification"); // e
            extend_all(e, this.ulabel, "row_classification");  // shift+e
            change_class(e, this.ulabel, "row_classification"); // g
            if (e.key == "r") {
                this.ulabel.show_initial_crop();
            }
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    console.log("Swap to binary");
                    this.rgb_displayed = false;
                    this.ulabel.swap_frame_image(this.binary_image_url);
                }
                else {
                    console.log("Swap to RGB");
                    this.rgb_displayed = true;
                    this.ulabel.swap_frame_image(this.rgb_image_url);
                }
            }
            if (e.key == "Enter") {
                document.getElementById("submit").click();
            }
        });
    },
    async mounted() {
        this.build_exit_buttons();

        // Attempt unlock on beforeunload
        window.addEventListener("beforeunload", (e) => {
            console.log(e)
            unlock_image(this.field_json, this.image_name, this.user);
        });


        // Check qa_status for current subimage
        await this.load_and_run_all();
    },
    destroyed() {
        delete this.ulabel;
        unlock_image(this.field_json, this.image_name, this.user);
    },
    methods: {
        /**
         * Exit QA, with a flag to submit the current image 
         * @param {*} submit_current_image 
         */
        exit_qa(submit_current_image) {
            this.leave_qa_cycle = true;
            if (submit_current_image) {
                document.getElementById("submit").click();
            } else {
                // Unlock image, then redirect to home page
                unlock_image(this.field_json, this.image_name, this.user).then(() => {
                    location.href = "/";
                });
            }
        },
        build_exit_buttons() {
            // Attach relevant divs to the container
            let navbar_button_div = document.getElementById("navbar-button-div");
            if ('innerHTML' in navbar_button_div) {
                navbar_button_div.innerHTML = '';
            }
            navbar_button_div.appendChild(document.getElementById("navbar-back-next"));
        },
        go_without_saving(idx_change) {
            // Navigate to previous image
            var new_subimage_idx = this.subimage_idx + idx_change;

            // Prevent out of bounds navigation
            if (new_subimage_idx < 0 || new_subimage_idx >= this.n_subimages) {
                console.log("Prevent out of bounds navigation");
            } else {
                // TODO find new way to prevent nav when page is dirty
                this.subimage_idx = new_subimage_idx;
                this.update_store();
                this.reload_ulabel();
            }

        },
        update_store() {
            // Store values in case of reject
            this.$store.commit("update$field_json", this.field_json);
            this.$store.commit("update$image_name", this.image_name);
            this.$store.commit("update$subimage_idx", this.subimage_idx);
            this.$store.commit("update$n_h_subimages", this.n_h_subimages);
            this.$store.commit("update$n_w_subimages", this.n_w_subimages);
            this.$store.commit("update$user", this.user);
        },
        async load_and_run_all() {
            let next_image = await get_next_outsourced_image_to_qa(this.user)
            console.log(next_image);
            if (next_image == "Nothing to QA.") { 
                document.getElementById("navbar-image-name").innerHTML = next_image;
                return;
            }
            
            let field_item = next_image.field_item;
            this.image_name = next_image.image_name;

            // Navbar image name hack
            document.getElementById("navbar-image-name").innerHTML = "Image: " + this.image_name;
            this.field_json = field_item.field_json;
            this.field_name = field_item.field_name;
            this.image_tags = field_item.image_tags[this.image_name];

            this.n_h_subimages = 3;
            this.n_w_subimages = 3;
            this.n_subimages = this.n_h_subimages * this.n_w_subimages;


            this.image_height = 0;
            this.image_width = 0;
            this.update_store();

            let lock_acquired = await lock_image(this.field_json, this.image_name, this.user);
            if (!lock_acquired) {
                alert("Image is locked by another user. Please try again later.");
                location.href = "/plant_count_qa";
            }

            let data = await get_subimage_qa_status(
                this.field_name, this.field_json, this.image_name
            );
            this.qa_status = data["subimages"];
            this.image_qa_status = data["ppa_qa"];
            this.subimage_qa_status = data["subimages"]

            await this.get_image();
            this.field_tags = await get_all_field_tags(this.field_json);
            try {
                this.buffer_pct = parseInt(this.field_tags["image_buffer"]["value"]);
            }
            catch (e) {
                console.log("Error getting buffer_pct:", e);
            }
            console.log("Buffer pct:", this.buffer_pct)
            this.startULabel();
        },
        async reload_ulabel() {
            let annos = this.preproc_annotations(this.annotations);


            let subimage_poly_annos = make_all_subimages_bboxes_with_large_rejection_x(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_qa_status,
                this.buffer_pct
            );
            this.ulabel.swap_frame_image(this.rgb_image_url);

            this.ulabel.set_annotations(annos, "plant_counting");
            this.ulabel.set_annotations(subimage_poly_annos, "subimage_bounds");
        },

        read_size_cookie() {
            let subtask_name = "plant_counting"
            let cookie_name = subtask_name + "_size=";
            let cookie_array = document.cookie.split(";");
            for (let i = 0; i < cookie_array.length; i++) {
                let current_cookie = cookie_array[i];
                //while there's whitespace at the front of the cookie, loop through and remove it
                while (current_cookie.charAt(0) == " ") {
                    current_cookie = current_cookie.substring(1);
                }
                if (current_cookie.indexOf(cookie_name) == 0) {
                    return parseFloat(current_cookie.substring(cookie_name.length, current_cookie.length))
                }
            }
            return null
        },

        async get_image() {
            // Get keypoints
            let params = {
                field_json: this.field_json,
                image_name: this.image_name,
                annotation_dir: "annotations"
            };
            let [rgb_image_url, annotations] = await get_image_and_annotations(params);
            this.rgb_image_url = rgb_image_url;
            this.annotations = annotations;

            // Get rows
            try {
                params.annotation_dir = "row_annotations";
                [rgb_image_url, annotations] = await get_image_and_annotations(params);
                this.row_annotations = annotations;
            } catch (e) {
                console.log("No row annotations found");
                this.row_annotations = [];
            }

            // Get image height and width
            let img = await getMeta(this.rgb_image_url);
            this.image_height = img.height;
            this.image_width = img.width;

            params = {
                field_json: this.field_json
            }

            hit_terraform_api(params, "get_binary_field_tag").then((data) => {
                get_binary_url(this.field_json, this.image_name, data.data).then((url) => {
                    this.binary_image_url = url;
                });
            });
        },
        async on_submit(annotations) {
            // Get keypoint slider value
            // TODO have ULabel store this as a state which is easier to access 
            let slider_val;
            try {
                slider_val = $("input#filter-low-confidence")[0].value;
            }
            catch (e) { 
                // TODO not be bad 
                slider_val = $("input#keypoint-slider")[0].value;
            }

            // Set keypoint slider val
            let keypoint_slider_params = {
                field_json: this.field_json,
                image_name: this.image_name,
                tag_name: "keypoint_slider_val",
                tag_dict: {"value": slider_val}
            };
            hit_terraform_api(keypoint_slider_params, "add_image_tag");

            console.log(annotations);
            annotations = this.preproc_annotations(annotations);
            // Semantics to keep formatting consistent.
            var keypoint_post_annotations_params = {
                annotations_json: {
                    annotations: {
                        main: [annotations["annotations"]["plant_counting"]],
                    },
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json,
                subimage_idx: -2,  // Hardcode just to be safe
            };

            // Prevent leave page warning
            this.ulabel.set_saved(true);

            // Submit annotations, with negative subimage index to skip spurious markings
            await hit_terraform_api(keypoint_post_annotations_params, "save_ppa_qa");
            mark_is_modified_by(this.field_json, this.image_name, this.user);

            // For outsourcing, always mark whole image as approved
            let approval_params = {
                field_json: this.field_json,
                update_items: [
                    {
                        "image_name": this.image_name,
                        "qa_type": "ppa_qa",
                        "qa_val": "approved",
                    }
                ]
            }
            await hit_terraform_api(approval_params, "batch_update_qa");
            console.log(await ecs_create_single_image_thumbnails(this.field_json, this.image_name));

            // If everything occurred as expected, unlock 
            await unlock_image(this.field_json, this.image_name, this.user);

            // Apparently routing with the vue router
            // doesn't close Ulabel properly, so forcibly
            // redirect so that ulabel dies
            if (this.leave_qa_cycle) {
                // Go back to progress
                location.href = "/";
            } else {
                // Continue qa
                location.href = '/plant_count_qa';
            }



        },
        next_incomplete_subimage() {
            // Return the total number of subimages so that we progress
            // to the next qa job
            return this.n_subimages;
        },
        /**
         * Calculates the distance from a point to a line segment. 
         * Lifted directly from ULabel's codebase.
         * 
         * @param point_x The point's x position
         * @param point_y The point's y position
         * @param line_x1 The first endpoint of the line's x position
         * @param line_y1 The first endpoint of the line's y position
         * @param line_x2 The second endpoint of the line's x position
         * @param line_y2 The second endpoint of the line's y position
         * @returns The distance from the point to the line segment
         */
        calculate_distance_from_point_to_line(
            point_x, 
            point_y, 
            line_x1, 
            line_y1, 
            line_x2, 
            line_y2 
        ) {
            let A = point_x - line_x1
            let B = point_y - line_y1
            let C = line_x2 - line_x1
            let D = line_y2 - line_y1
        
            let dot = A * C + B * D
            let len_sq = C * C + D * D

            // Initialize the param variable
            let param

            // Check for a divide by 0 error in the case of 0 length line
            if (len_sq != 0) {
                param = dot / len_sq;
            }

            let xx, yy
        
            // If param is still undefined then the line should have 0 length 
            // In which case we can set xx and yy equal to any endpoint
            if (param === undefined) {
                xx = line_x1
                yy = line_y1        
            }
            else if (param < 0) {
                xx = line_x1
                yy = line_y1
            }
            else if (param > 1) {
                xx = line_x2
                yy = line_y2
            }
            else {
                xx = line_x1 + param * C
                yy = line_y1 + param * D
            }
        
            let dx = point_x - xx
            let dy = point_y - yy
            return Math.sqrt(dx * dx + dy * dy)
        },
        /**
         * Calculates the distance from a point annotation to each segment of a polyline annotation, then returns the smallest distance.
         * Lifted directly from ULabel: TODO refactor ULabel to expose this
         * Modified to be Javascript instead of Typescript
         * 
         * @param point_annotation Point annotation to get the distance of
         * @param line_annotation Line annotation the point annotation is being compared against
         * @param offset Offset of a particular annotation in the set. Used when an annotation is being moved by the user
         * @returns The distance from a point to a polyline
         */
        get_distance_from_point_to_line(point_annotation, line_annotation, offset = null) 
        {
            // Create constants for the point's x and y value
            const point_x = point_annotation.spatial_payload[0][0]
            const point_y = point_annotation.spatial_payload[0][1]

            // Initialize the distance from the point to the polyline
            let distance

            // Loop through each segment of the polyline
            for (let idx = 0; idx < line_annotation.spatial_payload.length - 1; idx++) {

                // Create constants for the segment's endpoints' x and y values
                const line_x1 = line_annotation.spatial_payload[idx][0]
                const line_y1 = line_annotation.spatial_payload[idx][1]
                const line_x2 = line_annotation.spatial_payload[idx + 1][0]
                const line_y2 = line_annotation.spatial_payload[idx + 1][1]

                // Create offset variables
                let line_offset_x = 0
                let line_offset_y = 0

                // Only apply the offset when the line annotation id matches with the offset id
                // Check if offset !== null first to avoid an issue with reading properties of null
                if ((offset !== null) && (line_annotation.id === offset.id)) {
                    line_offset_x = offset.diffX
                    line_offset_y = offset.diffY
                }

                // Calculate the distance from the point to the line segment
                const distance_to_segment = this.calculate_distance_from_point_to_line(
                    point_x,
                    point_y,
                    line_x1 + line_offset_x,
                    line_y1 + line_offset_y,
                    line_x2 + line_offset_x,
                    line_y2 + line_offset_y
                )

                // Check if the distance to this segment is undefined or less than the distance to another segment
                if (distance === undefined || distance_to_segment < distance) {
                    distance = distance_to_segment
                }
            }

            return distance
        },
        /**
         * Filter keypoints based on distance to row, emulating ULabel deprecation
         * @param {Array} keypoint_annos Array of keypoint annotations
         * @param {Array} row_annos Array of row annotations
         * @param {Number} dist_px Distance in pixels 
         */
        filter_on_row_dist(keypoint_annos, row_annos, dist_px) { 
            let DEPRECATOR_KEY = "distance_from_line"
            var dep_counter = 0
            for (let keypoint of keypoint_annos) {
                let min_dist
                for (let row of row_annos) {
                    let current_dist = this.get_distance_from_point_to_line(keypoint, row)
                    if (min_dist === undefined || current_dist < min_dist) {
                        min_dist = current_dist
                    }
                }
                if (min_dist > dist_px) {
                    // Deprecate 
                    if (keypoint.deprecated_by === undefined || keypoint.deprecated_by === null) {
                        keypoint.deprecated_by = {}
                    }
                    keypoint.deprecated_by[DEPRECATOR_KEY] = true
                    keypoint.deprecated = true
                    dep_counter += 1
                }
            }
            console.log("Deprecated " + dep_counter + "/" + keypoint_annos.length + " keypoints.") 


        },
        preproc_annotations(annotations) {
            let anno_size = this.read_size_cookie();
            for (var i = 0; i < annotations.length; i++) {
                annotations[i].line_size = anno_size;
            }
            return annotations;
        },
        /**
         * Preprocess row annotations. 
         * Primarily used on older fields if the class_id isn't properly set. 
         * 
         * @param {Array} row_annos Row annotations to preprocess.
         */
        preproc_row_annotations(row_annos) {
            let anno_size = this.read_size_cookie();
            for (var i = 0; i < row_annos.length; i++) {
                row_annos[i].line_size = anno_size;
                // Verify classification payloads, or set default
                if (row_annos[i].classification_payloads.length == 1) {
                    if (row_annos[i].classification_payloads[0].class_id == null)
                    {
                        row_annos[i].classification_payloads[0].class_id = 10;
                    }
                }
            }
            return row_annos;
        },
        ulabel_bbox_delete() {
            return () => { };
        },
        startULabel() {
            var keypoint_annos = this.preproc_annotations(this.annotations);
            // this.preproc_row_annotations(this.row_annotations);
            this.filter_on_row_dist(keypoint_annos, this.row_annotations, 40)

            let subimage_poly_annos = make_all_subimages_bboxes_with_large_rejection_x(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_qa_status,
                this.buffer_pct,
                1,
                50,
                true
            );
            // TODO use subtask utils
            let subtasks = {
                plant_counting: {
                    display_name: "Plant Counting",
                    classes: [
                        {
                            name: "Plant",
                            color: "yellow",
                            id: 1,
                        },
                    ],
                    allowed_modes: ["point", "bbox", "polygon"],
                    resume_from: keypoint_annos,
                    task_meta: null,
                    annotation_meta: null,
                },
                row_classification: {
                    display_name: "Row Classification",
                    classes: [
                        {
                            name: "Male",
                            color: "blue",
                            id: 10,
                        },
                        {
                            name: "Female",
                            color: "white",
                            id: 11,
                        },
                    ],
                    allowed_modes: ["polyline"],
                    resume_from: this.row_annotations,
                    task_meta: null,
                    annotation_meta: null,
                    inactive_opacity: 0.0,
                },
                subimage_bounds: {
                    display_name: "Subimage Bounds",
                    classes: [
                        {
                            name: "Subimage Bound",
                            color: "black",
                            id: 1,
                        },
                        {
                            name: "Rejected Subimage",
                            color: "red",
                            id: 2,
                        },
                    ],
                    allowed_modes: ["bbox", "polyline"],
                    resume_from: subimage_poly_annos,
                    task_meta: null,
                    annotation_meta: null,
                    inactive_opacity: 0.9,
                },
            };
            var email = get_username(this);
            if (email === undefined) {
                email = "Unauthenticated MFStand User"
            }

            let keypoint_confidence_slider_val = 30; // Default to 30
            let row_dist_slider_val = 50; // Default to 50

            if (this.image_tags != null && "keypoint_slider_val" in this.image_tags) {
                keypoint_confidence_slider_val = parseInt(this.image_tags["keypoint_slider_val"]["value"]);
                console.log("Keypoint slider value found in image tags: " + keypoint_confidence_slider_val);
            } else if ("keypoint_confidence_slider_default_value" in this.field_tags) {
                keypoint_confidence_slider_val = parseInt(this.field_tags["keypoint_confidence_slider_default_value"]["value"]);
                console.log("Keypoint slider default value found in field tags: " + keypoint_confidence_slider_val);
            } else {
                console.log("No keypoint slider value saved, leaving as default: " + keypoint_confidence_slider_val);
            }

            // This is invariant to whether or not QA has been performed
            if (this.image_tags != null && "row_dist_slider_val" in this.image_tags) {
                row_dist_slider_val = parseInt(this.image_tags["row_dist_slider_val"]["value"]);
                console.log("Row dist slider value found in image tags: " + row_dist_slider_val);
            } else if ("row_distance_slider_default_value" in this.field_tags) {
                row_dist_slider_val = parseInt(this.field_tags["row_distance_slider_default_value"]["value"]);
                console.log("Row dist slider default value found in field tags: " + row_dist_slider_val);
            } else {
                console.log("No row dist slider value saved, leaving as default: " + row_dist_slider_val);
            }

            // Config object
            let config_data = {
                "filter_low_confidence_default_value": keypoint_confidence_slider_val / 100,
                "filter_row_distance_default_value": row_dist_slider_val,
                "keypoint_slider_default_value": keypoint_confidence_slider_val / 100,
            };


            // Initial ULabel configuration
            let ulabel = new ULabel(
                "container",        // container_id
                this.rgb_image_url, // image_data
                email,              // username
                this.on_submit,     // on_submit
                subtasks,           // subtasks
                null,               // task_meta
                null,               // annotation_meta
                1,                  // px_per_px
                null,      // initial_crop
                2,                  // Initial Line Size
                null,               // instructions_url
                config_data,        // config data
            );

            this.ulabel = ulabel;

            let ff_fn = this.force_filter;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                // ulabel.on(ulabel.config["done_callback"], () => {

                // })
                /*
                So this is absolutely horrible practice to deal with bonkers behavior 
                Basically the .on function in ulabel uses the functions .name property to reassign the callback
                For some reason vue-cli-service build sets all function.name properties to 'value'
                which breaks this callback behavior.
                Manually changing the property like this fixes it for the build version.
                */
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                )
                // Delete within bbox hack
                ulabel.on(ulabel.finish_annotation, () => {
                    delete_annos_in_bbox(ulabel);
                    delete_annos_in_polygon(ulabel);
                });

                // Force filter on load via clicking the inc/dec buttons
                ff_fn();
            });

            this.enable_submit();
            // this.disable_submit();
        },
        force_filter() {
            console.log("Forcibly clicking filter buttons...");
            let inc_button = $(".button.inc.keypoint-slider-increment.keypoint-slider-button")[0];
            let dec_button = $(".button.dec.keypoint-slider-increment.keypoint-slider-button")[0];
            console.log(inc_button);
            console.log(dec_button);
            inc_button.click();
            dec_button.click();
        },
        disable_submit() {
            // Hack to disallow submit before dirtying the page
            document.getElementById("submit").removeAttribute("href");
        },
        enable_submit() {
            // Hack to allow submit before dirtying the page
            // Ensure submit button is always pressable
            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type === "attributes") {
                        // When button is modified, make sure it can still be pressed
                        if (!document.getElementById("submit").hasAttribute("href")) {
                            document.getElementById("submit").href = "#";
                        }
                    }
                });
            });
            observer.observe(document.getElementById("submit"), {
                attributes: true //configure it to listen to attribute changes
            });
        }
    },
};
</script>

<style>
#container #toolbox div.keypoint-slider div.keypoint-slider-holder {
    padding: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
}

#container #toolbox div.keypoint-slider div.keypoint-slider-holder span.increment {
    bottom: 0.5em;
}

#container {
    width: 100%;
    height: calc(100vh - 66px);
    position: absolute;
    top: 66px;
    left: 0;
}
</style>